import React, { useEffect, useState } from "react";
import { Link, navigate, graphql, useStaticQuery } from "gatsby";
import axios from "axios";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Hero, OrderItem, IconBox } from "../components/common";
import { useAuth } from "../hooks";
import { apiURL } from "../helpers";

import Loader from "../assets/icons/loader.gif";

function DashboardPage({ location }) {
  const [orders, setOrders] = useState({
    loading: false,
    data: [],
  });

  let lemonSqueezyCheckoutRefreshed = false;

  useEffect(() => {
    if (
      typeof window?.LemonSqueezyCheckout !== "undefined" &&
      !lemonSqueezyCheckoutRefreshed
    ) {
      window.LemonSqueezyCheckout.refresh();
      lemonSqueezyCheckoutRefreshed = true;
    }
  }, []);

  useEffect(() => {
    if (
      typeof window?.LemonSqueezyCheckout !== "undefined" &&
      !lemonSqueezyCheckoutRefreshed
    ) {
      window.LemonSqueezyCheckout.refresh();
      lemonSqueezyCheckoutRefreshed = true;
    }
  });

  const { isAuthenticated, state } = useAuth();

  const {
    allStrapiProduct: {
      edges: [
        {
          node: { data: products },
        },
      ],
    },
  } = useStaticQuery(graphql`
    query AllDashboardProducts {
      allStrapiProduct {
        edges {
          node {
            id
            data {
              attributes {
                Name
                Price
                ProductCheckoutCode
                FeaturedImage {
                  data {
                    attributes {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(placeholder: TRACED_SVG)
                        }
                      }
                      formats {
                        large {
                          url
                          name
                          height
                          width
                        }
                      }
                    }
                    id
                  }
                }
                ProductId
                URL
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      getOrders();
    }
  }, [isAuthenticated]);
  const getOrders = async () => {
    setOrders({ ...orders, loading: true });
    const { data } = await axios.get(apiURL + `/orders?id=${state.user.id}`, {
      headers: {
        Authorization: `Bearer ${state.jwt}`,
      },
    });

    setOrders({ ...orders, loading: false, data });
  };
  const renderItems = () => {
    const items = [];

    const ordersIds = orders.data.map(
      (order) => order.data[0].attributes?.product_id
    );

    ordersIds.forEach((id) => {
      const product = products.find(
        (item) => item.attributes.ProductId === String(id)
      );

      if (product) {
        items.push(product);
      }
    });

    return items.map((product) => (
      <OrderItem
        email={state?.user?.email}
        productCode={product?.attributes?.ProductCheckoutCode}
        key={product.attributes.id}
        title={product.attributes.Name}
        image={product.attributes.FeaturedImage.data.attributes.localFile}
      />
    ));
  };

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Dashboard"
        path={location?.pathname}
      />
      <Helmet>
        <script
          src="https://app.lemonsqueezy.com/js/checkout.js"
          defer
        ></script>
        <script async defer>
          {`
            let checkoutCreated = false;

            const createCheckout = () => {
              if( window?.createLemonSqueezyCheckout ) {
                window.createLemonSqueezyCheckout();
                checkoutCreated = true;
              }
            }
            
            const interval = setInterval(() => {
              if(!checkoutCreated) {
                createCheckout();
              } else {
                clearInterval(interval);
              }
            }, 100);
          `}
        </script>
      </Helmet>
      <section className="mt-20 mb-16">
        <Hero
          title="Your Purchases"
          description="Download, keep, use & enjoy your purchases forever. If you need any assistance, we are just an email away."
        />
      </section>
      <section className="mb-40">
        {orders.loading ? (
          <div className="flex items-center justify-center w-full">
            <img alt="loader" src={Loader} />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {renderItems()}
          </div>
        )}
      </section>
      <section className="mb-24">
        <div className="w max-w-3xl text-center m-auto mb-24">
          <h2>Thanks for the Purchases! Share Your Happiness With Us!</h2>
        </div>
        <div className="grid grid-cols-6 gap-8">
          <IconBox
            className="col-span-6 md:col-span-3 lg:col-span-2"
            icon="vg-mail"
            title="Share Your Happiness"
          >
            How is our products? <Link to="/">Email us</Link> your happiness or
            feedback!
          </IconBox>
          <IconBox
            className="col-span-6 md:col-span-3 lg:col-span-2"
            icon="vg-facebook"
            title="Stay in Touch"
          >
            Connect with us on <Link to="/">Facebook</Link>,{" "}
            <Link to="/">Twitter</Link>, <Link to="/">Instagram</Link>,{" "}
            <Link to="/">Pinterest</Link>, <Link to="/">Youtube</Link>
          </IconBox>
          <IconBox
            className="col-span-6 md:col-span-6 lg:col-span-2"
            icon="vg-message"
            title="And Get Featured"
          >
            Write a cool testimonial on social media and tag/mention us!
          </IconBox>
        </div>
      </section>
    </Layout>
  );
}

DashboardPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default DashboardPage;
